import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogSinglePost from "../components/Blog/BlogSinglePost";

const Post = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="UL Certification for Secondary Containment Sump"
            description="We’re thrilled to announce that Painted Rhino is now UL certified on our secondary containment sump! Learn more about what this means for safety and quality."
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <BlogSinglePost
            title="Painted Rhino’s Secondary Containment Sump UL Certification "
            date="May 24, 2021"
            author="Painted Rhino"
            category=""
            featuredImage={data.featuredImage.childImageSharp.fluid}
            content={
               <>
                  <p>
                     We are pleased to announce that we’ve been UL certified on their secondary containment sump. UL certification (Underwriter
                     Laboratories) is the premier testing and monitoring company for manufacturers looking to maintain the integrity and stringent
                     quality standards of their product as required by the EPA (Environmental Protection Agency). It is also the most recognized
                     standard in the oil in gas industry for contractors and state inspectors.{" "}
                  </p>

                  <p>
                     The EPA is requiring more and more safeguards for ground water contaminants due to leakage. Our secondary containment sumps are a
                     great way to capture valve, conduit, and coupling leaks that may occur in the field. These sumps allow the conduit with all the
                     valves to pass through them so that any leaks are contained by the sump itself. In California the EPA requires a double wall sump
                     with a vacuum chamber. This way, technicians can periodically check the integrity of the tank by using a vacuum fluid and
                     monitoring tubes to assure the sump itself is leak proof. Painted Rhino Inc.’s secondary containment sump part numbers are 2885S
                     and 2885DW. These sumps can be purchased directly from Painted Rhino Inc.{" "}
                  </p>

                  <p>
                     What’s next for Painted Rhino Inc? Painted Rhino now has UL certification along with IAPMO certification. Our Quality Inspectors
                     have passed and been certified by the ISO 9001 system. The next logical step in the future will be for Painted Rhino as a company
                     to become part of the ISO program. Stay tuned.
                  </p>

                  <p>
                     For any questions or for more information about our certifications, feel free to{" "}
                     <a data-modal-open="modal-contact">contact us</a>!{" "}
                  </p>
               </>
            }
         />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/FB_Global.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Twitter_Global.jpg" }) {
         publicURL
      }
      featuredImage: file(relativePath: { eq: "blog/ul-logo.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 2500) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
   }
`;

export default Post;
